import { styled } from '@ori-ui/mui';

import {
  LEFT_BOTTOM_DESKTOP_WIDTH,
  LEFT_BOTTOM_MOBILE_WIDTH,
  LEFT_TOP_DESKTOP_WIDTH,
  RIGHT_BOTTOM_DESKTOP_WIDTH,
  RIGHT_BOTTOM_MOBILE_WIDTH,
  RIGHT_TOP_DESKTOP_WIDTH,
  RIGHT_TOP_MOBILE_WIDTH,
} from './constants';

export const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    alignItems: 'unset',
    flexDirection: 'row',
    gap: theme.spacing(12),
  },
}));
export const LeftPanel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(12),
}));
export const RightPanel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(12),
  paddingTop: theme.spacing(14),
}));

export const LeftTop = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: LEFT_TOP_DESKTOP_WIDTH,
  },
}));

export const LeftBottom = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  maxWidth: LEFT_BOTTOM_MOBILE_WIDTH,
  [theme.breakpoints.up('sm')]: {
    maxWidth: LEFT_BOTTOM_DESKTOP_WIDTH,
  },
}));

export const RightTop = styled('div')(({ theme }) => ({
  maxWidth: RIGHT_TOP_MOBILE_WIDTH,
  [theme.breakpoints.up('sm')]: {
    maxWidth: RIGHT_TOP_DESKTOP_WIDTH,
  },
}));

export const RightBottom = styled('div')(({ theme }) => ({
  maxWidth: RIGHT_BOTTOM_MOBILE_WIDTH,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(-2),
    maxWidth: RIGHT_BOTTOM_DESKTOP_WIDTH,
  },

  '.button-wrapper': {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
    marginTop: theme.spacing(4),
  },
}));
