import type { DecodeHtml } from '@ori/presentation-components';

// Number of paragraphs loaded on seo-box when there are more "Read More" will be visible.
export const PARAGRAPH_FIRST_LOAD = 3;

export const READ_MORE_LESS = 'read-more-less';

export const elementsProps: DecodeHtml['elementsProps'] = {
  headingProps: {
    align: 'center',
  },
};
