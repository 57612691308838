import { IntroText, TopBanner } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { renderOrThrowError } from '../../../utils';
import { EditorialInfo } from '../../EditorialInfo';
import { HEADER_AREA } from './constants';
import { Inner, Root } from './styles';
import type { HeaderAreaProps } from './types';

export const HeaderArea = ({ breadcrumbs, debug, header, modulePosition }: HeaderAreaProps) => {
  const { getTestId } = useTestId();

  const { authorName, headline, text, imageUrl, photographerName, publishedDate, extraLongHeadline } = header;

  if (!headline && !imageUrl) {
    return renderOrThrowError({
      debug,
      message: 'EditorialHeader must have specified headline or imageUrl.',
      modulePosition,
      layout: 'EditorialHeader',
    });
  }

  return (
    <Root data-testid={getTestId(HEADER_AREA)}>
      <TopBanner
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        title={imageUrl ? undefined : headline}
        extraLongHeadline={extraLongHeadline}
      />
      <Inner>
        <EditorialInfo
          authorName={authorName}
          photographerName={photographerName}
          publishedDate={publishedDate}
        />
        <IntroText text={text} />
      </Inner>
    </Root>
  );
};
