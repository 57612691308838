import { useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { generateSrcSet, getImageVariants } from '@ori/image-sizes-helper';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import { isNonNullable } from '@ori/ts-utils';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { LAYOUTS_WITH_BUTTON_INSIDE_ON_MOBILE } from '../../../common';
import { getLayoutContentAlignment } from '../../../utils/getLayoutContentAlignment';
import { Button } from '../Button';
import { Title } from '../Title';
import { Bottom, ContentWrapper, InnerContentWrapper, LayoutWrapper, Root, StyledImage } from './styles';
import type { ImageBoxProps } from './types';

export const ImageBox: FC<ImageBoxProps> = (props) => {
  const { layout, module, titleMaxLines, moduleNumber, imageParams, sizes } = props;
  const isMobile = useIsMobile();
  const { getTestId } = useTestId();
  const { palette } = useTheme();

  const onImageBoxClick = useCallback(() => {
    if (module?.link?.relativeUrl) {
      window.location.href = module.link.relativeUrl;
    }
  }, [module?.link?.relativeUrl]);

  const imageUrl = module?.backgroundImage?.url ?? '';
  const imagesVariants = useMemo<ImageSizesHelperParams | null>(
    () =>
      imageUrl
        ? {
            url: imageUrl,
            imageSizeMultiplier: 1,
            backgroundColor: palette.common.white,
            imageFormat: 'WebP',
            params: imageParams ?? [],
          }
        : null,
    [imageParams, imageUrl, palette.common.white],
  );

  if (layout == null || module == null) {
    return null;
  }

  const alignment = getLayoutContentAlignment(layout, moduleNumber);

  const { link, backgroundImage, backgroundType, content } = module;
  const { relativeUrl, data } = link ?? {};
  const hasLinkRelativeUrl = Boolean(relativeUrl);
  const hasLinkData = Boolean(data);
  const hasLink = hasLinkRelativeUrl || hasLinkData;

  if (!content && !backgroundImage) {
    return null;
  }

  const sharedComponents = (
    <>
      {isNonNullable(content?.title) ? (
        <Title
          title={content.title}
          maxLines={titleMaxLines}
          isMobile={isMobile}
          alignment={alignment}
          titleColor={content.titleColor}
          layout={layout}
        />
      ) : null}

      {link && hasLink ? (
        <Button
          viewType="image"
          link={link}
          isMobile={isMobile}
          alignment={alignment}
          layout={layout}
        />
      ) : null}
    </>
  );

  return (
    <Root
      data-testid="image-box"
      hasLink={hasLinkRelativeUrl}
      horizontalInput={alignment.horizontal}
      onClick={onImageBoxClick}
    >
      <LayoutWrapper onClick={undefined}>
        <StyledImage
          alt={content?.title ?? ''}
          srcSet={imageParams && imagesVariants ? generateSrcSet(getImageVariants(imagesVariants)) : imageUrl}
          sizes={sizes}
          data-testid={getTestId('background-image')}
        />
        {isMobile && !LAYOUTS_WITH_BUTTON_INSIDE_ON_MOBILE.includes(layout) ? (
          <Bottom
            data-testid={getTestId('bottom-content')}
            layout={layout}
          >
            {sharedComponents}
          </Bottom>
        ) : (
          <ContentWrapper
            data-testid={getTestId('content')}
            alignment={alignment}
            backgroundType={backgroundType ?? undefined}
            onClick={undefined}
          >
            <InnerContentWrapper
              data-testid={getTestId('inner-content')}
              alignment={alignment}
              layout={layout}
              hasTitle={!!content?.title}
            >
              {sharedComponents}
            </InnerContentWrapper>
          </ContentWrapper>
        )}
      </LayoutWrapper>
    </Root>
  );
};
