import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  margin: '0 auto',
  p: {
    padding: theme.spacing(2),
  },
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));
