import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { ImageBox, TextBox } from '../shared';
import {
  LEFT_BOTTOM_IMAGE_PARAMS,
  LEFT_PANEL,
  LEFT_TOP_IMAGE_PARAMS,
  RIGHT_PANEL,
  RIGHT_TOP_IMAGE_PARAMS,
} from './constants';
import { Root, LeftPanel, RightPanel, LeftTop, LeftBottom, RightTop, RightBottom } from './styles';

export const ThreeMediaRightBottomTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const isMobile = useIsMobile();
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const [callToAction, productDisplayRight, productDisplayLeft, textArea] = modules;
  const leftTop = (
    <LeftTop>
      <ImageBox
        id={id}
        module={callToAction}
        layout={EditorialBannerLayoutEnum.ThreeMediaRightBottomText}
        moduleNumber={0}
        imageParams={LEFT_TOP_IMAGE_PARAMS.params}
        sizes={LEFT_TOP_IMAGE_PARAMS.sizes}
      />
    </LeftTop>
  );
  const leftBottom = (
    <LeftBottom>
      <ImageBox
        id={id}
        module={productDisplayLeft}
        layout={EditorialBannerLayoutEnum.ThreeMediaRightBottomText}
        moduleNumber={1}
        imageParams={LEFT_BOTTOM_IMAGE_PARAMS.params}
        sizes={LEFT_BOTTOM_IMAGE_PARAMS.sizes}
      />
    </LeftBottom>
  );
  const rightTop = (
    <RightTop>
      <ImageBox
        id={id}
        moduleNumber={2}
        module={productDisplayRight}
        layout={EditorialBannerLayoutEnum.ThreeMediaRightBottomText}
        imageParams={RIGHT_TOP_IMAGE_PARAMS.params}
        sizes={RIGHT_TOP_IMAGE_PARAMS.sizes}
      />
    </RightTop>
  );
  const rightBottom = (
    <RightBottom>
      <TextBox
        id={id}
        layout={EditorialBannerLayoutEnum.ThreeMediaRightBottomText}
        module={textArea}
        textMaxLines={isMobile ? 4 : 3}
        titleMaxLines={2}
        moduleNumber={3}
      />
    </RightBottom>
  );

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.ThreeMediaRightBottomText)}>
      {isMobile ? (
        <>
          {leftTop}
          {rightBottom}
          {rightTop}
          {leftBottom}
        </>
      ) : (
        <>
          <LeftPanel data-testid={getTestId(LEFT_PANEL)}>
            {leftTop}
            {leftBottom}
          </LeftPanel>
          <RightPanel data-testid={getTestId(RIGHT_PANEL)}>
            {rightTop}
            {rightBottom}
          </RightPanel>
        </>
      )}
    </Root>
  );
};
