import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesContentError } from '../../utils';
import { Root, Headline } from './styles';

export const HeadlineLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout } = banner;
  const { getTestId } = useTestId();

  if (!modules?.[0]?.content) {
    return getModulesContentError({ debug, layout, modulePosition });
  }

  const { title } = modules[0].content;

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.Headline)}>
      <Headline
        textAlign="center"
        variant="h2"
      >
        {title}
      </Headline>
    </Root>
  );
};
