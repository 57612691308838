import { DateFormat, useFormatDate } from '@ori/culture';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { EDITORIAL_INFO } from './constants';
import { Root } from './styles';
import type { EditorialInfoProps } from './types';

export const EditorialInfo: FC<EditorialInfoProps> = ({ authorName, publishedDate, photographerName }) => {
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const formattedPublishedDate = useFormatDate(new Date(publishedDate ?? ''), DateFormat.ShortDate, {
    toLocalTimezone: true,
  });

  if (!publishedDate && !authorName && !photographerName) {
    return null;
  }

  const editorialInfo = [
    publishedDate ? `${translations.published}:\u00A0${formattedPublishedDate}` : null,
    authorName ? `${translations.author}:\u00A0${authorName}` : null,
    photographerName ? `${translations.photo}:\u00A0${photographerName}` : null,
  ]
    .filter(Boolean)
    .join('  |  ');

  return <Root data-testid={getTestId(EDITORIAL_INFO)}>{editorialInfo}</Root>;
};
