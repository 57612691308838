import type { TypographyVariant } from '@ori-ui/mui';
import { theme } from '@ori-ui/mui';
import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesContentError } from '../../utils';
import { getLayoutContentAlignment } from '../../utils/getLayoutContentAlignment';
import { Root, Text } from './styles';

export const QuoteLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { getTestId } = useTestId();

  const { modules, layout } = banner;

  if (!modules?.[0]?.content) {
    return getModulesContentError({ debug, layout, modulePosition });
  }

  const { backgroundColor, content } = modules[0];
  const { title, titleColor, titleSize } = content;

  const alignment = getLayoutContentAlignment(EditorialBannerLayoutEnum.Quote, 0);

  const variantMapping: Record<string, Extract<TypographyVariant, 'h6' | 'h5'>> = {
    H5: 'h5',
    H6: 'h6',
  };

  const variant = titleSize == null ? 'h5' : variantMapping[titleSize];

  const mappedBackgroundColor = mapBackendColorToTheme({
    backendColor: backgroundColor?.type,
    fallBackColor: theme.palette.brown[400],
    theme,
  });
  const mappedTitleColor = mapBackendColorToTheme({
    backendColor: titleColor,
    fallBackColor: theme.palette.common.white,
    theme,
  });

  return (
    <Root
      data-testid={getTestId(EditorialBannerLayoutEnum.Quote)}
      backgroundColor={mappedBackgroundColor}
      titleColor={mappedTitleColor}
      alignment={alignment}
    >
      <Text
        variant={variant}
        component={variant}
        textAlign="center"
      >
        {title}
      </Text>
    </Root>
  );
};
