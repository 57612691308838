import type { SpecificErrorProps } from '../../utils';
import { renderOrThrowError } from '../../utils';

const EmptyModule = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({ debug, message: 'Module must not be empty.', modulePosition, layout });

const UnknownTypename = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({
    debug,
    message: 'Module must have typename specified correctly.',
    modulePosition,
    layout,
  });

export const Errors = {
  EmptyModule,
  UnknownTypename,
};
