import { styled } from '@ori-ui/mui';
import { TextWithHtml } from '@ori/presentation-components';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { TextProps } from './types';

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

// Add property viewAll boolean to unset maxHeight
export const TextWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<{ viewAll: boolean; showReadMoreOption?: boolean | null | undefined }>(({ showReadMoreOption, viewAll }) => ({
  maxHeight: showReadMoreOption && !viewAll ? 240 : 'none',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

export const Text = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<TextProps>(({ theme, maxLines, isParagraphText }) => ({
  display: '-webkit-box',
  WebkitLineClamp: maxLines,
  WebkitBoxOrient: 'vertical',
  marginBottom: theme.spacing(2),
  width: 'auto',
  overflow: isParagraphText ? 'visible' : 'hidden',
}));

export const ButtonWrapper = styled('div')({
  alignSelf: 'center',
});

export const ReadMore = styled('span')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  fontSize: theme.typography.pxToRem(14),
  alignSelf: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
  '&:svg': {
    margin: theme.spacing(0, 'auto'),
    width: 24,
    height: 24,
  },
}));

export const StyledTextWithHtml = styled(TextWithHtml)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
