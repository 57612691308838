import { styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 2),
}));
export const Headline = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0, 2),
  margin: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.grey[900],
}));
