import { Skeleton } from '@ori-ui/mui';

import { SkeletonRoot } from './styles';

export const EditorialPageSkeleton = () => (
  <SkeletonRoot>
    <Skeleton
      variant="rectangular"
      width="800px"
      height="240px"
      animation="pulse"
    />
    <Skeleton
      variant="text"
      width="500px"
      height="120px"
      animation="pulse"
    />
    <Skeleton
      variant="text"
      width="200px"
      height="20px"
      animation="pulse"
    />
    <Skeleton
      variant="text"
      width="200px"
      height="20px"
      animation="pulse"
    />

    <Skeleton
      variant="rectangular"
      width="100%"
      height="500px"
      animation="pulse"
    />

    <Skeleton
      variant="rectangular"
      width="800px"
      height="500px"
      animation="pulse"
    />

    <Skeleton
      variant="rectangular"
      width="100%"
      height="500px"
      animation="pulse"
    />
  </SkeletonRoot>
);
