import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 9999,
  backgroundColor: theme.palette.common.white,
}));
