import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  '.title-base': {
    width: '100%',
    maxWidth: 'none',
  },
  maxWidth: '100%',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    '.title-base': {
      width: '50%',
    },
    maxWidth: 984,
  },
}));
