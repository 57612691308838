import type { ErrorPageContentProps } from '@ori-appshell/error-page';
import { ErrorPageContent } from '@ori-appshell/error-page/dist/cjs/components/ErrorPageContent';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { FULL_SCREEN_ERROR } from './constants';
import { Root } from './styles';

export interface FullScreenErrorProps extends Omit<ErrorPageContentProps, 'statusCode'> {
  statusCode?: number;
}
export const FullScreenError: FC<FullScreenErrorProps> = ({ statusCode = 404, ...props }) => {
  const { getTestId } = useTestId();

  return (
    <Root data-testid={getTestId(FULL_SCREEN_ERROR)}>
      <ErrorPageContent
        statusCode={statusCode}
        {...props}
      />
    </Root>
  );
};
