import { NoSsr } from '@ori-ui/mui';
import { EditorialBannerLayoutEnum, VideoType } from '@ori/graphql-schema';
import { Video } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { YOUKU_MEDIA_URL, YOUTUBE_MEDIA_URL } from '../../common';
import { getModulesContentError, renderOrThrowError } from '../../utils';
import { ExternalVideoRoot, VideoWrapper } from './styles';

export const VideoLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout } = banner;
  const { getTestId } = useTestId();

  if (!modules?.[0]) {
    return getModulesContentError({ debug, layout, modulePosition });
  }

  const { backgroundVideo } = modules[0];

  if (!backgroundVideo) {
    return renderOrThrowError({
      debug,
      message: `${layout} module must have backgroundVideo set.`,
      modulePosition,
      layout,
    });
  }

  const { playInLoop, id, type } = backgroundVideo;

  if (!id) {
    return renderOrThrowError({
      debug,
      message: `${layout} module backgroundVideo must have id set.`,
      modulePosition,
      layout,
    });
  }

  if (type !== VideoType.Mp4) {
    const embedUrl = type === VideoType.Youtube ? YOUTUBE_MEDIA_URL : YOUKU_MEDIA_URL;

    return (
      <ExternalVideoRoot>
        {/* eslint-disable-next-line react/iframe-missing-sandbox -- Need to use both sandbox values. */}
        <iframe
          allowFullScreen
          sandbox="allow-same-origin allow-scripts allow-presentation"
          title="Video"
          data-testid="iframe-video"
          src={`${embedUrl}${id}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </ExternalVideoRoot>
    );
  }

  return (
    <NoSsr>
      <VideoWrapper>
        <Video
          src={id}
          playInLoop={playInLoop ?? false}
          data-testid={getTestId(EditorialBannerLayoutEnum.Video)}
        />
      </VideoWrapper>
    </NoSsr>
  );
};
