import type { Alignment } from '../common';

export const getPosition = ({ vertical, horizontal }: Alignment) => {
  const floatRight = horizontal === 'right' ? 'flex-end' : 'center';
  const floatTop = vertical === 'top' ? 'flex-start' : 'center';

  return {
    verticalAlignment: vertical === 'bottom' ? 'flex-end' : floatTop,
    horizontalAlignment: horizontal === 'left' ? 'flex-start' : floatRight,
  };
};
