/**
 * Firstly there were data about alignment in the response from BE, they changed it after few months of development to be static positioned
 * Original data:
 * modules: [
 *  {
 *     ...
 *    content: {
 *     ...
 *    ***alignment: {
 *     horizontalInput: 'center',
 *     verticalInput: 'bottom',
 *    },***
 *    ...
 *  So to be future proof I'm leaving this options in the components and created this mapping as I expect they will need this (to be editable by editor)
 */
import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';

import type { Alignment } from '../common';

type LayoutContentPosition = {
  [key in EditorialBannerLayoutEnum]?: {
    moduleAlignment: Alignment[];
  };
};

export const LAYOUT_CONTENT_POSITION: LayoutContentPosition = {
  [EditorialBannerLayoutEnum.OneMediaCenterBottomText]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
    ],
  },

  [EditorialBannerLayoutEnum.TwoMediaRightTopText]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
    ],
  },

  [EditorialBannerLayoutEnum.ThreeMediaRightBottomText]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
    ],
  },

  [EditorialBannerLayoutEnum.FourMedia]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
    ],
  },

  [EditorialBannerLayoutEnum.TwoMedia]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
    ],
  },

  [EditorialBannerLayoutEnum.ParagraphText]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'center',
      },
    ],
  },

  [EditorialBannerLayoutEnum.Quote]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'center',
      },
    ],
  },

  [EditorialBannerLayoutEnum.OneMediaParagraphText]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'bottom',
      },
      {
        horizontal: 'center',
        vertical: 'center',
      },
    ],
  },

  [EditorialBannerLayoutEnum.Video]: {
    moduleAlignment: [
      {
        horizontal: 'center',
        vertical: 'center',
      },
    ],
  },
};

export const getLayoutContentAlignment = (layout: EditorialBannerLayoutEnum, moduleNumber: number): Alignment => {
  const positionData = LAYOUT_CONTENT_POSITION[layout]?.moduleAlignment[moduleNumber];

  return (
    positionData ?? {
      horizontal: 'center',
      vertical: 'center',
    }
  );
};
