import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import type { EditorialPageProps } from '../components';
import { EditorialPage } from '../components';
import { APP_NAME, APP_TEAM_NAME, translationsNamespace } from '../constants';
import { mainLogger } from '../logger';

export type EditorialPageContainerProps = EditorialPageProps;

/**
 * Component that wraps `EditorialPage` with relevant Providers (TestIdProvider, ThemeProvider, TranslationsProvider).
 */
export const EditorialPageContainer: FC<EditorialPageContainerProps> = ({
  breadcrumbs,
  config,
  data,
  pageId,
  hideRating,
}) => (
  <TestIdProvider
    team={APP_TEAM_NAME}
    project={APP_NAME}
  >
    <LoggerProvider logger={mainLogger}>
      <ErrorBoundary areaName="EditorialPageContainer">
        <TranslationsNamespaceProvider namespace={translationsNamespace}>
          <EditorialPage
            breadcrumbs={breadcrumbs}
            config={config}
            data={data}
            pageId={pageId}
            hideRating={hideRating}
          />
        </TranslationsNamespaceProvider>
      </ErrorBoundary>
    </LoggerProvider>
  </TestIdProvider>
);
