import { Button, styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { SharedWrapperProps } from '../../../common';
import { getPosition } from '../../../utils';

export const QuickBuyButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
}));

export const ButtonWrapper = styled(Button, {
  shouldForwardProp: shouldForwardProp(['color', 'variant']),
})<SharedWrapperProps>(({ theme, alignment }) => {
  const calculated = getPosition(alignment);

  return {
    display: 'flex',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'horizontal',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    selfAlign: calculated.horizontalAlignment,
    justifyContent: calculated.verticalAlignment,
    borderRadius: theme.spacing(5),
    bottom: calculated.verticalAlignment === 'top' ? 0 : theme.spacing(6),
    top: 0,
    height: theme.spacing(9),
    textAlign: 'center',
    padding: theme.spacing(2, 6),
    maxWidth: 'fit-content',
    zIndex: 2,
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('sm')]: {
      top: 'auto',
      textAlign: 'initial',
      margin: theme.spacing(4, 0),
    },
  };
});
