import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { ImageBox } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { Root, TopLeft, BottomLeft, BottomRight, TopRight } from './styles';

export const FourMediaLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const isMobile = useIsMobile();
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const titleMaxLines = isMobile ? 3 : 4;
  const [topLeft, topRight, bottomLeft, bottomRight] = modules;

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.FourMedia)}>
      <TopLeft>
        <ImageBox
          moduleNumber={0}
          titleMaxLines={titleMaxLines}
          module={topLeft}
          layout={EditorialBannerLayoutEnum.FourMedia}
          id={id}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </TopLeft>
      <TopRight>
        <ImageBox
          id={id}
          moduleNumber={1}
          titleMaxLines={titleMaxLines}
          module={topRight}
          layout={EditorialBannerLayoutEnum.FourMedia}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </TopRight>
      <BottomLeft>
        <ImageBox
          id={id}
          moduleNumber={2}
          titleMaxLines={titleMaxLines}
          module={bottomLeft}
          layout={EditorialBannerLayoutEnum.FourMedia}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </BottomLeft>
      <BottomRight>
        <ImageBox
          id={id}
          moduleNumber={3}
          titleMaxLines={titleMaxLines}
          module={bottomRight}
          layout={EditorialBannerLayoutEnum.FourMedia}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </BottomRight>
    </Root>
  );
};
