import { styled } from '@ori-ui/mui';

import { IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  gap: theme.spacing(3),

  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    gap: theme.spacing(6),
    margin: 'auto',
    width: 'initial',
  },
}));

const FourMediaItem = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: IMAGE_DESKTOP_WIDTH,
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
  },
}));

export const TopLeft = styled(FourMediaItem)({
  gridArea: '1 / 1 / 3 / 3',
});

export const TopRight = styled(FourMediaItem)({
  gridArea: '1 / 3 / 3 / 5',
});

export const BottomLeft = styled(FourMediaItem)({
  gridArea: '3 / 1 / 5 / 3',
});

export const BottomRight = styled(FourMediaItem)({
  gridArea: '3 / 3 / 5 / 5',
});
