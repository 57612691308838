import { styled, Typography, type TypographyProps } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import { getPosition } from '../../utils';
import type { QuoteTextProps } from './types';

const DEFAULT_HEIGHT = 250;
const DEFAULT_HEIGHT_MOBILE = 210;

export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(['height']),
})<Pick<QuoteTextProps, 'alignment' | 'backgroundColor' | 'titleColor'>>(({
  backgroundColor,
  titleColor,
  theme,
  alignment,
}) => {
  const calculated = getPosition(alignment);

  return {
    backgroundColor,
    color: titleColor,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: calculated.horizontalAlignment,
    height: DEFAULT_HEIGHT_MOBILE,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: DEFAULT_HEIGHT,
      padding: theme.spacing(2, 0),
    },
  };
});

export const Text = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  width: 360,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    width: 600,
    padding: 0,
  },
}));
