import { getImageParamsAndSizes } from '../../utils';

export const LEFT_TEXT_IMAGE_DESKTOP_WIDTH = 584;
export const LEFT_TEXT_IMAGE_PARAMS = getImageParamsAndSizes({
  desktopWidth: LEFT_TEXT_IMAGE_DESKTOP_WIDTH,
});
export const RIGHT_TEXT_IMAGE_DESKTOP_WIDTH = 492;
export const RIGHT_TEXT_IMAGE_PARAMS = getImageParamsAndSizes({
  desktopWidth: RIGHT_TEXT_IMAGE_DESKTOP_WIDTH,
});
export const DESKTOP_WIDTH_TEXT_ON_LEFT = 779;
export const DESKTOP_WIDTH_TEXT_ON_RIGHT = 649;
