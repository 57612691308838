import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { getModulesContentAndLengthError } from '../../utils';
import { ImageBox, TextBox } from '../shared';
import { LEFT_TEXT_IMAGE_PARAMS, RIGHT_TEXT_IMAGE_PARAMS } from './constants';
import { ImageWrapper, Root, TextWrapper } from './styles';
import type { OneMediaOverlayTextLayoutProps } from './types';

export const OneMediaOverlayTextLayout = ({
  banner,
  debug,
  position,
  modulePosition,
}: OneMediaOverlayTextLayoutProps) => {
  const isMobile = useIsMobile();
  const { getTestId } = useTestId();
  const { modules, layout, id } = banner;
  const IMAGE_PARAMS = position === 'left' ? LEFT_TEXT_IMAGE_PARAMS : RIGHT_TEXT_IMAGE_PARAMS;

  if (layout == null || modules == null || modules.length < 1) {
    return getModulesContentAndLengthError({ debug, layout, modulePosition });
  }

  return (
    <Root
      data-testid={getTestId(
        position === 'left'
          ? EditorialBannerLayoutEnum.OneMediaOverlayLeftText
          : EditorialBannerLayoutEnum.OneMediaOverlayRightText,
      )}
      position={position}
    >
      <ImageWrapper position={position}>
        <ImageBox
          id={id}
          moduleNumber={0}
          layout={layout}
          module={modules[0]}
          textMaxLines={undefined}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </ImageWrapper>
      <TextWrapper position={position}>
        <TextBox
          id={id}
          moduleNumber={1}
          titleMaxLines={isMobile ? 2 : 3}
          layout={layout}
          module={modules[1]}
        />
      </TextWrapper>
    </Root>
  );
};
