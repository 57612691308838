import { NoSsr } from '@ori-ui/mui';
import type { OlapicLibConfiguration } from '@ori/olapic-lib';
import { OlapicWidget } from '@ori/olapic-lib';
import type { FC } from 'react';

// Replace fallback with skeleton after skeleton ready.
// Right now only dummy data will be updated after data from BE.
export const Olapic: FC<OlapicLibConfiguration> = (config) => (
  <NoSsr>
    <OlapicWidget {...config} />
  </NoSsr>
);
