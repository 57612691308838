import type { EditorialBannerLayoutEnum, EditorialPageContent, Maybe } from '@ori/graphql-schema';
import { isSSR } from '@ori/presentation-utils';

import type { WithDebug, WithPosition } from '../common';
import { EditorialErrorMessage } from '../components/EditorialErrorMessage';

export interface SpecificErrorProps extends WithPosition, WithDebug {
  layout?: Maybe<EditorialBannerLayoutEnum | EditorialPageContent['__typename']>;
}

interface ErrorProps extends SpecificErrorProps {
  message: string;
}

export const renderOrThrowError = ({ debug, message, modulePosition, layout }: ErrorProps) => {
  if (!debug) {
    return null;
  }
  if (isSSR()) {
    return (
      <EditorialErrorMessage
        errorMessage={message}
        modulePosition={modulePosition}
        module={layout}
      />
    );
  }
  throw new Error(message);
};

export const getModulesLengthError = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({
    debug,
    message: `${layout} must have at least one module.`,
    modulePosition,
    layout,
  });

export const getModulesContentError = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({ debug, message: `${layout} must have content.`, modulePosition, layout });

export const getModulesContentAndLengthError = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({
    debug,
    message: `${layout} must have layout specified and have at least one module.`,
    modulePosition,
    layout,
  });
