import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  maxWidth: '1306px',
  margin: theme.spacing(0, 'auto'),
  padding: theme.spacing(0, 3),
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4, 0),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8, 0),
    marginBottom: theme.spacing(20),
  },
}));

export const SkeletonRoot = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2, 0),
  span: {
    margin: 0,
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    width: '1306px',
  },
}));

export const EditorialCarouselRoot = styled('div')(({ theme }) => ({
  maxWidth: '1078px',
  margin: theme.spacing(0, 'auto', 5, 'auto'),
}));
