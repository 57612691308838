import { styled, Typography, type TypographyProps } from '@ori-ui/mui';
import type { Maybe } from '@ori/graphql-schema';
import { EditorialBannerLayoutEnum, EditorialBannerTitleColorEnum } from '@ori/graphql-schema';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { TitleProps } from './types';

type TitleBaseProps = Omit<TitleProps, 'isMobile' | 'title'> & Pick<TypographyProps, 'component'>;
export const TitleBase = styled(Typography, {
  shouldForwardProp: shouldForwardProp(['variant', 'component']),
})<TitleBaseProps>(({ theme, alignment, titleColor, layout, maxLines }) => {
  const themeColorFromTitleColor = (color?: Maybe<EditorialBannerTitleColorEnum>) => {
    switch (color) {
      case EditorialBannerTitleColorEnum.Black:
        return theme.palette.grey[900];
      case EditorialBannerTitleColorEnum.DarkGrey:
        return theme.palette.text.primary;
      case EditorialBannerTitleColorEnum.White:
        return theme.palette.common.white;
      default:
        return theme.palette.grey[900];
    }
  };

  return {
    color: themeColorFromTitleColor(titleColor),
    letterSpacing: 0,
    lineHeight: '3rem',
    margin: theme.spacing(3, 3),
    maxWidth: '100%',
    textAlign:
      layout === EditorialBannerLayoutEnum.OneMediaOverlayLeftText ||
      layout === EditorialBannerLayoutEnum.OneMediaOverlayRightText
        ? 'center'
        : alignment.horizontal,
    display: '-webkit-box',
    WebkitLineClamp: maxLines,
    lineClamp: maxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      lineHeight: '4rem',
      margin: theme.spacing(2, 0, 6, 0),
      maxWidth: 'inherit',
    },
  };
});
