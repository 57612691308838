import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { useIsMobile } from '@ori/presentation-hooks';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import type { SharedComponentProps } from '../../../common';
import { getLayoutContentAlignment } from '../../../utils/getLayoutContentAlignment';
import { Button } from '../Button';
import { Title } from '../Title';
import { ReadMoreLess } from './ReadMoreLess';
import { elementsProps, PARAGRAPH_FIRST_LOAD } from './constants';
import { ButtonWrapper, Root, StyledTextWithHtml, TextWrapper } from './styles';
import type { HtmlText } from './types';
import { replaceLinks as createReplaceLinks } from './utils';

const getTextKey = (index: number) => `text-${index}`;

export const TextBox: FC<SharedComponentProps> = ({ layout, module, textMaxLines, titleMaxLines, moduleNumber }) => {
  const isMobile = useIsMobile();
  const [paragraphsLoaded, setParagraphsLoaded] = useState(PARAGRAPH_FIRST_LOAD);
  const [viewAll, setViewAll] = useState(false);

  const toggleReadMoreOrLess = useCallback(
    (textCollectionLength: number | undefined) => () => {
      setViewAll((prevState) => !prevState);
      setParagraphsLoaded(viewAll ? PARAGRAPH_FIRST_LOAD : (textCollectionLength ?? PARAGRAPH_FIRST_LOAD));
    },
    [viewAll],
  );

  const transformedHtml = useCallback((html?: HtmlText | null) => {
    if (!html?.text) {
      return null;
    }
    const { text, textLinks } = html;
    // If you will add more replace functions, you can add them here, use pipeable way
    const replaceLinks = createReplaceLinks(textLinks);

    return replaceLinks(text);
  }, []);

  if (layout == null || module == null) {
    return null;
  }
  const alignment = getLayoutContentAlignment(layout, moduleNumber);

  const { content, link } = module;

  if (!content) {
    return null;
  }

  const { textCollection, title, titleColor, showReadMoreOption } = content;

  const isParagraphText = layout === EditorialBannerLayoutEnum.ParagraphText;

  return (
    <Root>
      <Title
        maxLines={titleMaxLines}
        isMobile={isMobile}
        alignment={alignment}
        titleColor={titleColor}
        layout={layout}
        title={title ?? ''}
      />
      <TextWrapper
        viewAll={viewAll}
        showReadMoreOption={showReadMoreOption}
      >
        {textCollection?.slice(0, showReadMoreOption ? paragraphsLoaded : undefined).map((text, index) => (
          <StyledTextWithHtml
            key={getTextKey(index)}
            isOverflowVisible={isParagraphText}
            html={transformedHtml(text)}
            maxLines={textMaxLines}
            elementsProps={elementsProps}
          />
        ))}
      </TextWrapper>
      {showReadMoreOption ? (
        <ReadMoreLess
          viewAll={viewAll}
          toggle={toggleReadMoreOrLess(textCollection?.length)}
        />
      ) : null}
      {link ? (
        <ButtonWrapper>
          <Button
            viewType="text"
            link={link}
            isMobile={isMobile}
            alignment={alignment}
            layout={layout}
          />
        </ButtonWrapper>
      ) : null}
    </Root>
  );
};
