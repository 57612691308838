import type { HtmlText } from '../types';

// Function for replacing links in text in pipeable way
export const replaceLinks =
  (textLinks: HtmlText['textLinks']) =>
  (inputText: string): string => {
    if (!textLinks || textLinks.length === 0) {
      return inputText;
    }

    return inputText.replaceAll(/{(\d+)}/g, (_, index: string) => {
      const link = textLinks[Number.parseInt(index, 10)];
      if (!link) {
        return '';
      }
      const url = link.relativeUrl ?? '#';
      const title = link.title ?? 'Link';

      return `<a data-index="${index}" href="${url}">${title}</a>`;
    });
  };
