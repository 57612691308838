import type { EditorialPageBannerFragment } from '@ori/editorial-fetching';
import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';

import type { WithDebug, WithPosition } from '../common';
import { OverlayPosition } from '../common';
import { FourMediaLayout } from '../components/FourMediaLayout';
import { HeadlineLayout } from '../components/HeadlineLayout';
import { OneMediaCenterBottomTextLayout } from '../components/OneMediaCenterBottomTextLayout';
import { OneMediaOverlayTextLayout } from '../components/OneMediaOverlayTextLayout';
import { ParagraphTextLayout } from '../components/ParagraphTextLayout';
import { QuoteLayout } from '../components/QuoteLayout';
import { ThreeMediaRightBottomTextLayout } from '../components/ThreeMediaRightBottomTextLayout';
import { OneMediaParagraphTextLayout, TwoMediaLayout } from '../components/TwoMediaLayout';
import { TwoMediaRightTopTextLayout } from '../components/TwoMediaRightTopTextLayout';
import { VideoLayout } from '../components/VideoLayout';
import type { SpecificErrorProps } from './errors';
import { renderOrThrowError } from './errors';

const LayoutError = ({ debug, modulePosition, layout }: SpecificErrorProps) =>
  renderOrThrowError({
    debug,
    message: `${layout} must have layout specified correctly.`,
    modulePosition,
    layout,
  });

export interface CreateBannerParams extends WithPosition, WithDebug {
  banner: EditorialPageBannerFragment;
}

export const createBannerLayout = ({ banner, debug, modulePosition }: CreateBannerParams) => {
  switch (banner.layout) {
    case EditorialBannerLayoutEnum.OneMediaCenterBottomText:
      return (
        <OneMediaCenterBottomTextLayout
          key="one-media-center-bottom-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.OneMediaOverlayLeftText:
      return (
        <OneMediaOverlayTextLayout
          key="one-media-overlay-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
          position={OverlayPosition.Left}
        />
      );
    case EditorialBannerLayoutEnum.OneMediaOverlayRightText:
      return (
        <OneMediaOverlayTextLayout
          key="one-media-overlay-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
          position={OverlayPosition.Right}
        />
      );
    case EditorialBannerLayoutEnum.FourMedia:
      return (
        <FourMediaLayout
          key="four-media-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.Headline:
      return (
        <HeadlineLayout
          key="headline-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.OneMediaParagraphText:
      return (
        <OneMediaParagraphTextLayout
          key="one-media-paragraph-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.ParagraphText:
      return (
        <ParagraphTextLayout
          key="paragraph-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.Quote:
      return (
        <QuoteLayout
          key="quote-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.ThreeMediaRightBottomText:
      return (
        <ThreeMediaRightBottomTextLayout
          key="three-media-right-bottom-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.TwoMedia:
      return (
        <TwoMediaLayout
          key="two-media-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.TwoMediaRightTopText:
      return (
        <TwoMediaRightTopTextLayout
          key="two-media-right-top-text-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    case EditorialBannerLayoutEnum.Video:
      return (
        <VideoLayout
          key="video-layout"
          banner={banner}
          debug={debug}
          modulePosition={modulePosition}
        />
      );
    default:
      return (
        <LayoutError
          debug={debug}
          modulePosition={modulePosition}
          layout="EditorialBanner"
        />
      );
  }
};
