import type { EditorialPageProductBoxCarouselFragment } from '@ori/editorial-fetching';
import { mapProductBoxesFromGql } from '@ori/product-box';
import type { ProductBoxCarouselProps } from '@ori/product-box-carousel';
import { ProductBoxCarousel } from '@ori/product-box-carousel';
import { useMemo } from 'react';

import type { WithDebug, WithPosition } from '../../../common';
import { useTranslations } from '../../../hooks/useTranslations';
import { renderOrThrowError } from '../../../utils';
import { PRODUCT_CAROUSEL } from './constants';
import { Headline, Root } from './styles';

export interface ProductCarouselProps extends Omit<ProductBoxCarouselProps, 'data'>, WithPosition, WithDebug {
  products?: EditorialPageProductBoxCarouselFragment['products'];
  headline?: string | null;
  className?: string;
}

export const ProductCarousel = (props: ProductCarouselProps) => {
  const {
    debug,
    modulePosition,
    analyticsPlacement,
    customerId,
    headline,
    hideRating,
    products,
    tenant,
    classNames,
    className,
  } = props;
  const { translations } = useTranslations();
  const data = mapProductBoxesFromGql((products ?? []).filter((productBox) => !!productBox));

  const carouselTranslations = useMemo(
    () => ({
      favorites: translations.favorites,
      quickBuy: translations.quickBuy,
    }),
    [translations],
  );

  if (!products || products.length < 1) {
    return renderOrThrowError({
      debug,
      message: 'EditorialProductBoxCarousel must have at least one product.',
      modulePosition,
      layout: 'EditorialProductBoxCarousel',
    });
  }

  return (
    <Root
      className={className}
      data-testid={PRODUCT_CAROUSEL}
    >
      {headline ? <Headline variant="h3">{headline}</Headline> : null}
      <ProductBoxCarousel
        data={data}
        analyticsPlacement={analyticsPlacement}
        customerId={customerId}
        hideRating={hideRating}
        tenant={tenant}
        translations={carouselTranslations}
        classNames={classNames}
        id={`editorials-product-carousel-${modulePosition}`}
      />
    </Root>
  );
};
