import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { ImageBox, TextBox } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { OneMediaRoot, ImageWrapper, TextWrapper } from './styles';

export const OneMediaParagraphTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { getTestId } = useTestId();
  const { layout, modules, id } = banner;

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  return (
    <OneMediaRoot
      data-testid={getTestId(EditorialBannerLayoutEnum.OneMediaParagraphText)}
      className="split-layout"
    >
      <ImageWrapper>
        <ImageBox
          id={id}
          layout={layout}
          module={modules[0]}
          moduleNumber={0}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </ImageWrapper>
      <TextWrapper>
        <TextBox
          id={id}
          titleMaxLines={2}
          textMaxLines={undefined}
          layout={layout}
          module={modules[1]}
          moduleNumber={1}
        />
      </TextWrapper>
    </OneMediaRoot>
  );
};
