import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';

import type { ViewType } from '../common';

interface ButtonType {
  color: 'default' | 'bannerButton';
  variant: 'contained' | 'outlined' | 'text';
}

interface ButtonColorVariantLayout {
  layout: string;
  image: {
    mobile: ButtonType;
    desktop: ButtonType;
  };
  text: {
    mobile: ButtonType;
    desktop: ButtonType;
  };
}

const outlinedButton: ButtonType = {
  color: 'default',
  variant: 'outlined',
};

const containedButton: ButtonType = {
  color: 'bannerButton',
  variant: 'contained',
};

const layoutButtons: ButtonColorVariantLayout[] = [
  {
    layout: EditorialBannerLayoutEnum.ThreeMediaRightBottomText,
    image: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...outlinedButton,
      },
    },
  },
  {
    layout: EditorialBannerLayoutEnum.FourMedia,
    image: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
  },
  {
    layout: EditorialBannerLayoutEnum.OneMediaCenterBottomText,
    image: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
  },
  {
    layout: EditorialBannerLayoutEnum.TwoMediaRightTopText,
    image: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...outlinedButton,
      },
    },
  },
  {
    layout: EditorialBannerLayoutEnum.TwoMedia,
    image: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...outlinedButton,
      },
    },
  },
  {
    layout: EditorialBannerLayoutEnum.OneMediaParagraphText,
    image: {
      mobile: {
        ...containedButton,
      },
      desktop: {
        ...containedButton,
      },
    },
    text: {
      mobile: {
        ...outlinedButton,
      },
      desktop: {
        ...outlinedButton,
      },
    },
  },
];

export const getButtonColorAndVariant = (
  layout: EditorialBannerLayoutEnum,
  isMobile: boolean,
  viewType: ViewType,
): ButtonType => {
  const filtered: ButtonColorVariantLayout | undefined = layoutButtons.find((value) => value.layout === layout);

  if (filtered) {
    if (isMobile) {
      return filtered[viewType].mobile;
    }

    return filtered[viewType].desktop;
  }

  return outlinedButton;
};
