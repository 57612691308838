import { ErrorMessage } from '@ori/presentation-components';
import type { FC } from 'react';

import type { EditorialErrorMessageProps } from './types';

export const defaultModule = 'Unknown';

export const EditorialErrorMessage: FC<EditorialErrorMessageProps> = ({
  errorMessage,
  modulePosition,
  module,
  title,
}) => (
  <ErrorMessage title={title}>
    <strong>Module:</strong> {module ?? defaultModule}
    <br />
    <strong>Position:</strong> {modulePosition + 1}
    <br />
    <strong>Reason:</strong> {errorMessage}
  </ErrorMessage>
);
