import { styled, Typography } from '@ori-ui/mui';

export const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const Headline = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4),
  color: theme.palette.text.primary,
}));
