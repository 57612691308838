import { QuickBuyButton } from '@ori/presentation-components';
import { mapHasAutoSubscription } from '@ori/product-box';
import useTestId from '@ori/testid-generator';
import { isNonNullable } from '@ori/ts-utils';
import React from 'react';

import { getButtonColorAndVariant } from '../../../utils/buttons';
import { ButtonWrapper, QuickBuyButtonWrapper } from './styles';
import type { ButtonProps } from './types';

export const Button = ({ isMobile, alignment, layout, viewType, link }: ButtonProps) => {
  const { color, variant } = getButtonColorAndVariant(layout, isMobile, viewType);

  const { getTestId } = useTestId();

  const { title, relativeUrl, data } = link ?? {};

  if (title && relativeUrl) {
    return (
      <ButtonWrapper
        data-testid={getTestId('button')}
        alignment={alignment}
        layout={layout}
        className="button-wrapper"
        variant={variant}
        size="medium"
        href={relativeUrl}
        color={color}
      >
        {title}
      </ButtonWrapper>
    );
  }

  if (data && data.__typename === 'Product') {
    const price = data.price?.price?.currentPrice;
    if (!isNonNullable(price) || !data.productCode) {
      return null;
    }

    const isMultiShade = data.concept?.products && data.concept.products.length > 1;
    const hasAutoSubscription = 'labels' in data ? mapHasAutoSubscription(data.labels) : false;

    return (
      <QuickBuyButtonWrapper>
        <QuickBuyButton
          currentPrice={price.toString()}
          currentProductCode={data.productCode}
          name={data.concept?.name ?? ''}
          isMultiShade={Boolean(isMultiShade)}
          hasAutoSubscription={hasAutoSubscription}
        />
      </QuickBuyButtonWrapper>
    );
  }

  return null;
};
