import { styled } from '@ori-ui/mui';

export const ExternalVideoRoot = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 'auto'),
    maxWidth: 984,
  },

  iframe: {
    aspectRatio: '16 / 9',
    width: '100%',
  },
}));

export const VideoWrapper = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 984,
  },
}));
