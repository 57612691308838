import { ArrowDownThin, ArrowUpThin } from '@ori-ui/icons';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { useTranslations } from '../../../hooks/useTranslations';
import { READ_MORE_LESS } from './constants';
import { ReadMore } from './styles';
import type { ReadMoreLessProps } from './types';

export const ReadMoreLess = ({ viewAll, toggle }: ReadMoreLessProps) => {
  const { translations } = useTranslations();
  const { getTestId } = useTestId();

  return (
    <ReadMore
      data-testid={getTestId(READ_MORE_LESS)}
      onClick={toggle}
    >
      {viewAll ? (
        <>
          <ArrowUpThin />
          {translations.readLess}
        </>
      ) : (
        <>
          {translations.readMore}
          <ArrowDownThin />
        </>
      )}
    </ReadMore>
  );
};
