import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { ImageBox, TextBox } from '../shared';
import { LEFT_IMAGE_PARAMS, RIGHT_IMAGE_PARAMS } from './constants';
import { Root, LeftSide, RightImage, HeadingTitle, RightSide } from './styles';

export const TwoMediaRightTopTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout, id } = banner;
  const isMobile = useIsMobile();
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const [first, second, third] = modules;

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.TwoMediaRightTopText)}>
      <LeftSide>
        <ImageBox
          id={id}
          layout={layout}
          module={first}
          moduleNumber={0}
          imageParams={LEFT_IMAGE_PARAMS.params}
          sizes={LEFT_IMAGE_PARAMS.sizes}
        />
      </LeftSide>
      <RightSide>
        <HeadingTitle>
          <TextBox
            id={id}
            titleMaxLines={isMobile ? 3 : 2}
            layout={layout}
            module={second}
            moduleNumber={1}
          />
        </HeadingTitle>
        <RightImage>
          <ImageBox
            id={id}
            layout={layout}
            module={third}
            moduleNumber={2}
            imageParams={RIGHT_IMAGE_PARAMS.params}
            sizes={RIGHT_IMAGE_PARAMS.sizes}
          />
        </RightImage>
      </RightSide>
    </Root>
  );
};
