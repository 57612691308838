import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { ImageBox } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { Root } from './styles';

export const OneMediaCenterBottomTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.OneMediaCenterBottomText)}>
      <ImageBox
        id={id}
        moduleNumber={0}
        module={modules[0]}
        layout={layout}
        titleMaxLines={isMobile ? 4 : 3}
        imageParams={IMAGE_PARAMS.params}
        sizes={IMAGE_PARAMS.sizes}
      />
    </Root>
  );
};
