import { Alert, Typography } from '@ori-ui/mui';
import { useToken } from '@ori/auth';
import { CategoriesSection, EditorialCarouselSection, mapEditorialCarousel } from '@ori/editorial-components';
import { type EditorialPageFragment, useGetEditorialCategoryOrPagePreviewData } from '@ori/editorial-fetching';
import { OlapicTypeEnum } from '@ori/olapic-lib';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { ErrorBoundary } from '../../containers/ErrorBoundary';
import { useTranslations } from '../../hooks/useTranslations';
import { getEditorialDebug } from '../../utils';
import { createBannerLayout } from '../../utils/createBannerLayout';
import { FullScreenError } from '../FullScreenError';
import { HeaderArea, Olapic, ProductCarousel } from '../standalone';
import { EditorialPageSkeleton } from './EditorialPageSkeleton';
import { Errors } from './Errors';
import { EDITORIAL_PAGE } from './constants';
import { EditorialCarouselRoot, Inner, Root } from './styles';
import type { EditorialPageProps } from './types';

export const EditorialPage: FC<EditorialPageProps> = ({ breadcrumbs, config, data: ssgData, pageId, hideRating }) => {
  const { tenant, language } = config;
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const { customerId } = useToken();
  const debug = getEditorialDebug();
  const { pagePreviewData, previewMode, loaded, hasError } = useGetEditorialCategoryOrPagePreviewData({
    pageId,
  });
  // istanbul ignore next -- internal use
  const showPreviewData = previewMode && loaded;
  // istanbul ignore next -- internal use
  const data = showPreviewData ? pagePreviewData : ssgData;

  if ((!data && !previewMode) || (!data && hasError && previewMode)) {
    return <FullScreenError language={language} />;
  }
  // istanbul ignore next -- reachable only in preview mode
  if (!data) {
    return <EditorialPageSkeleton />;
  }

  const relatedArticles = (data.editorialPage?.contentItems ?? []).filter(
    (module): module is EditorialPageFragment => module?.__typename === 'EditorialPage',
  );

  const layouts = data.editorialPage?.contentItems?.map((module, modulePosition) => {
    if (!module) {
      return (
        <ErrorBoundary
          // eslint-disable-next-line react/no-array-index-key -- No other suitable key
          key={`emptyModule-${modulePosition}`}
          debug={debug}
          modulePosition={modulePosition}
        >
          <Errors.EmptyModule
            debug={debug}
            modulePosition={modulePosition}
          />
        </ErrorBoundary>
      );
    }

    switch (module.__typename) {
      case 'EditorialHeader':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <HeaderArea
              key="header-area"
              breadcrumbs={breadcrumbs}
              debug={debug}
              header={module}
              modulePosition={modulePosition}
            />
          </ErrorBoundary>
        );
      case 'EditorialOlapic':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <Olapic
              key="olapic"
              type={module.type ?? OlapicTypeEnum.FrontPage}
              categoryId={module.categoryId ?? undefined}
              productCode={module.product?.productCode}
            />
          </ErrorBoundary>
        );
      case 'EditorialProductBoxCarousel':
        return (
          <ErrorBoundary
            // eslint-disable-next-line react/no-array-index-key -- No other suitable key
            key={`${module.__typename}-${modulePosition}`}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <ProductCarousel
              key="product-carousel"
              debug={debug}
              modulePosition={modulePosition}
              customerId={customerId}
              hideRating={hideRating}
              headline={module.headline}
              products={module.products}
              tenant={tenant}
              id={`editorials-product-carousel-${modulePosition}`}
            />
          </ErrorBoundary>
        );
      case 'EditorialBanner':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.layout}
            modulePosition={modulePosition}
          >
            {createBannerLayout({
              banner: module,
              debug,
              modulePosition,
            })}
          </ErrorBoundary>
        );
      case 'EditorialPage':
        return null;
      default:
        return (
          <ErrorBoundary
            // eslint-disable-next-line react/no-array-index-key -- No other suitable key
            key={`unknownTypename-${modulePosition}`}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <Errors.UnknownTypename
              debug={debug}
              modulePosition={modulePosition}
              layout={module.__typename}
            />
          </ErrorBoundary>
        );
    }
  });

  return (
    <Root data-testid={getTestId(EDITORIAL_PAGE)}>
      {showPreviewData ? (
        <Alert
          color="success"
          severity="info"
        >
          <Typography>PREVIEW MODE</Typography>
        </Alert>
      ) : null}

      <Inner>{layouts}</Inner>
      <EditorialCarouselRoot>
        <EditorialCarouselSection
          data={mapEditorialCarousel({ slides: relatedArticles })}
          labelRead={translations.readNow}
          title={translations.inspirationCorner}
          id="editorial-page-related-articles-carousel"
        />
      </EditorialCarouselRoot>
      <CategoriesSection
        categories={data.editorialLandingPages}
        title={translations.otherCategories}
        viewButtonText={translations.view}
      />
    </Root>
  );
};
