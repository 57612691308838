/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */
import type { TranslationKey } from '../models/TranslationKey';

export type DefaultTranslations = Record<keyof typeof TranslationKey, string>;

export const defaultTranslations: DefaultTranslations = {
  author: 'Author',
  favorites: 'Favorites',
  inspirationCorner: 'Inspiration corner',
  otherCategories: 'Other categories',
  photo: 'Photo',
  published: 'Published',
  quickBuy: 'Quick buy',
  readLess: 'Read less',
  readMore: 'Read more',
  readNow: 'Read now',
  view: 'View',
};

export const translationsNamespace = 'products_editorial-page';
