import useTestId from '@ori/testid-generator';
import React from 'react';

import { TitleBase } from './styles';
import type { TitleProps } from './types';

export const Title = ({
  isMobile,
  title,
  alignment,
  titleColor,
  layout,
  maxLines,
  variant: variantProp,
}: TitleProps) => {
  const { getTestId } = useTestId();

  const variant = variantProp ?? (isMobile ? 'h5' : 'h4');

  return (
    <TitleBase
      variant={variant}
      component={variant}
      data-testid={getTestId('title')}
      titleColor={titleColor}
      layout={layout}
      className="title-base"
      maxLines={maxLines}
      alignment={alignment}
    >
      {title}
    </TitleBase>
  );
};
