import { getImageParamsAndSizes } from '../../utils';

export const LEFT_TOP_DESKTOP_WIDTH = 510;
export const LEFT_TOP_IMAGE_PARAMS = getImageParamsAndSizes({
  desktopWidth: LEFT_TOP_DESKTOP_WIDTH,
});
export const LEFT_BOTTOM_DESKTOP_WIDTH = 350;
export const LEFT_BOTTOM_MOBILE_WIDTH = 300;
export const LEFT_BOTTOM_IMAGE_PARAMS = getImageParamsAndSizes({
  desktopWidth: LEFT_BOTTOM_DESKTOP_WIDTH,
  mobileWidth: LEFT_BOTTOM_MOBILE_WIDTH,
});
export const RIGHT_TOP_DESKTOP_WIDTH = 400;
export const RIGHT_TOP_MOBILE_WIDTH = 300;
export const RIGHT_TOP_IMAGE_PARAMS = getImageParamsAndSizes({
  desktopWidth: RIGHT_TOP_DESKTOP_WIDTH,
  mobileWidth: RIGHT_TOP_MOBILE_WIDTH,
});
export const RIGHT_BOTTOM_DESKTOP_WIDTH = 420;
export const RIGHT_BOTTOM_MOBILE_WIDTH = 330;

export const LEFT_PANEL = 'left-panel';
export const RIGHT_PANEL = 'right-panel';
